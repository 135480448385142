$(document).ready(function() {
	var STORAGE_NAME = 'special_version';
	var Settings = {};
	var DefaultBodyClasses = $('body').attr('class') || '';
	function getSettings() {
		var storage = window.localStorage.getItem(STORAGE_NAME);
		if (storage) {
			try {
				Settings = JSON.parse(storage);
			} catch(e) {
				console.log(e);
				return;
			}
		}
	}
	function setSettings() {
		$('.special_version_controls_select').each(function(i,e) {
			var name = $(e).data('parameter');
			var value = $(e).find('.active').data('value') || '';
			Settings[name] = value;
		});
		saveSettings();
	}
	function saveSettings() {
		window.localStorage.setItem(STORAGE_NAME, JSON.stringify(Settings));
		setBodyClass();
	}
	function deleteSettings() {
		window.localStorage.removeItem(STORAGE_NAME);
		Settings = {};
		setBodyClass();
	}
	function setBodyClass() {
		var classes = DefaultBodyClasses;
		for (var i in Settings) {
			classes += ' ' + i + '_' + Settings[i];
		}
		$('body').attr('class', classes);
	}
	function initialLoad() {
		getSettings();
		for (var i in Settings) {
			$('.special_version_controls_select[data-parameter="' + i + '"]').find('.special_version_controls_point[data-value="' + Settings[i] + '"]').addClass('active').siblings().removeClass('active');
		}
	}

	initialLoad();
	if (Object.keys(Settings).length) {
		setBodyClass();
		$('.js-open-special-version').addClass('active');
		window.scroll(0,0);
	}

	$('body').on('click', '#switch-special', function() {
		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			deleteSettings();
		} else {
			$(this).addClass('active');
			setSettings();
		}
	}).on('click', '.special_version_controls_point', function() {
		var name = $(this).parents('.special_version_controls_select').data('parameter');
		var val = $(this).data('value');
		if (val && name) {
			$(this).addClass('active').siblings().removeClass('active');
			setSettings();
		} else {
			console.error('Parameter not found');
			alert('Ошибка выбора параметров просмотра');
		}
	});
});